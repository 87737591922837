import {
    campaign_brand_speciality_data
  } from "../../js/path";
  
  export default {
    data() {
      return {
        pageNo: null,
        table_header: [],
        campaign_brand_spec_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "name",
            label: "Name"
          },
          {
            key: "key",
            label: "Key"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        form: {
          key: "", 
          name: "", 
          dark_color: "", 
          light_color: "", 
          background_image: "", 
          paperless_image: "", 
          icon_quality: "", 
          icon_brand: "", 
          icon_logo: "", 
          border_icon: "",
          is_active: 0,
          heading: "",
          sub_heading: ""
        },
        background_image: "",
        paperless_image: "",
        icon_quality: "",
        icon_brand: "",
        icon_logo: "",
        border_icon: "",
        background_img_url: "",
        border_icon_img_url: "",
        paperless_img_url: "",
        icon_quality_img_url: "",
        icon_brand_img_url: "",
        icon_logo_img_url: "",
        edit: {
          background_img_url: null,
          border_icon_img_url: null,
          paperless_img_url: null,
          icon_quality_img_url: null,
          icon_brand_img_url: null,
          icon_logo_img_url: null,
        },
      };
    },
    computed: {
      
    },
    methods: {
      readFile(e, txt) {
        if (txt == "background_image") {
          this.background_image = e.target.files[0];
          if (this.background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.background_image.reset();
            this.background_img_url = "";
            this.edit.background_img_url = "";
            this.background_image = "";
            return false;
          } else {
            this.background_img_url = URL.createObjectURL(this.background_image);
            this.edit.background_img_url = "";
            return true;
          }
        }
        if (txt == "border_icon") {
          this.border_icon = e.target.files[0];
          if (this.border_icon.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.border_icon.reset();
            this.border_icon_img_url = "";
            this.edit.border_icon_img_url = "";
            this.border_icon = "";
            return false;
          } else {
            this.border_icon_img_url = URL.createObjectURL(this.border_icon);
            this.edit.border_icon_img_url = "";
            return true;
          }
        }
        if (txt == "paperless_image") {
          this.paperless_image = e.target.files[0];
          if (this.paperless_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.paperless_image.reset();
            this.paperless_img_url = "";
            this.edit.paperless_image = "";
            this.paperless_image = "";
            return false;
          } else {
            this.paperless_img_url = URL.createObjectURL(this.paperless_image);
            this.edit.paperless_img_url = "";
            return true;
          }
        }
        if (txt == "icon_quality") {
          this.icon_quality = e.target.files[0];
          if (this.icon_quality.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.icon_quality.reset();
            this.icon_quality_img_url = "";
            this.edit.icon_quality_img_url = "";
            this.icon_quality = "";
            return false;
          } else {
            this.icon_quality_img_url = URL.createObjectURL(this.icon_quality);
            this.edit.icon_quality_img_url = "";
            return true;
          }
        }
        if (txt == "icon_brand") {
          this.icon_brand = e.target.files[0];
          if (this.icon_brand.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.icon_brand.reset();
            this.icon_brand_img_url = "";
            this.edit.icon_brand_img_url = "";
            this.icon_brand = "";
            return false;
          } else {
            this.icon_brand_img_url = URL.createObjectURL(this.icon_brand);
            this.edit.icon_brand_img_url = "";
            return true;
          }
        }
        if (txt == "icon_logo") {
          this.icon_logo = e.target.files[0];
          if (this.icon_logo.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.icon_logo.reset();
            this.icon_logo_img_url = "";
            this.edit.icon_logo_img_url = "";
            this.icon_logo = "";
            return false;
          } else {
            this.icon_logo_img_url = URL.createObjectURL(this.icon_logo);
            this.edit.icon_logo_img_url = "";
            return true;
          }
        } 
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchCampaignBrandSpecialityData(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
            if (responseData.key != "" && responseData.key != null) {
              this.form.key = responseData.key;
            }
            if (
              responseData.heading != "" &&
              responseData.heading != null
            ) {
              this.form.heading = responseData.heading;
            }
            if (
              responseData.sub_heading != "" &&
              responseData.sub_heading != null
            ) {
              this.form.sub_heading = responseData.sub_heading;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            if (responseData.background_image != "" && responseData.background_image != null) {
              this.edit.background_img_url = responseData.background_image ? responseData.background_image : "";
            }
            if (responseData.border_icon != "" && responseData.border_icon != null) {
              this.edit.border_icon_img_url = responseData.border_icon ? responseData.border_icon : "";
            }
            if (responseData.paperless_image != "" && responseData.paperless_image != null) {
              this.edit.paperless_img_url = responseData.paperless_image ? responseData.paperless_image : "";
            }
            if (responseData.icon_quality != "" && responseData.icon_quality != null) {
              this.edit.icon_quality_img_url = responseData.icon_quality ? responseData.icon_quality : "";
            }
            if (responseData.icon_brand != "" && responseData.icon_brand != null) {
              this.edit.icon_brand_img_url = responseData.icon_brand ? responseData.icon_brand : "";
            }
            if (responseData.icon_logo != "" && responseData.icon_logo != null) {
              this.edit.icon_logo_img_url = responseData.icon_logo ? responseData.icon_logo : "";
            }
            if (responseData.dark_color != "" && responseData.dark_color != null) {
              this.form.dark_color = responseData.dark_color ? responseData.dark_color : "";
            }
            if (responseData.light_color != "" && responseData.light_color != null) {
              this.form.light_color = responseData.light_color ? responseData.light_color : "";
            }
            this.campaign_brand_spec_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async deleteCampaignBrandSpecialityData(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restoreCampaignBrandSpecialityData(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_brand_speciality_data.restoremrAppBrandSpecialityData + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl;
          if (this.$route.name == "edit-campaign-brand-speciality-data") {
            url = campaign_brand_speciality_data.mrAppcampaignBrandSpecialityDataUrl + "/" + this.campaign_brand_spec_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }
  
          if (this.background_image) {
            dataAppend.append("background_image", this.background_image);
          }

          if (this.border_icon) {
            dataAppend.append("border_icon", this.border_icon);
          }

          if (this.paperless_image) {
            dataAppend.append("paperless_image", this.paperless_image);
          }

          if (this.icon_quality) {
            dataAppend.append("icon_quality", this.icon_quality);
          }

          if (this.icon_brand) {
            dataAppend.append("icon_brand", this.icon_brand);
          }

          if (this.icon_logo) {
            dataAppend.append("icon_logo", this.icon_logo);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active" &&
              key != "background_image" &&
              key != "border_icon" &&
              key != "paperless_image" &&
              key != "icon_quality" &&
              key != "icon_brand" &&
              key != "icon_logo"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-campaign-brand-speciality-data") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/field-rep-app/campaign-brand-speciality-data");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-campaigns")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-campaigns")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-campaigns")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-campaigns")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
    },
    created() {
      if (
        this.$route.name == "add-campaign-brand-speciality-data" ||
        this.$route.name == "edit-campaign-brand-speciality-data"
      ) {
        if (this.$route.name == "edit-campaign-brand-speciality-data") {
          this.fetchCampaignBrandSpecialityData(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  